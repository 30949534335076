import 'core-js/modules/es.promise';
import whenDomReady from 'when-dom-ready';
import siteConfig from '../modules/siteConfig';
import extend from '../modules/extend';
/* import trackEvents from "../modules/trackEvents"; */

class BasePage {
    constructor(...opts) {
        extend(this, opts);
        this.init();
    }

    init() {
        var self = this;
        self.ready = false;
        self.onBeforeReady();

        whenDomReady().then(() => {
            self.onReady();
        });

        window.addEventListener('load', () => {
            self.onLoad();
        });
    }

    onBeforeReady() {
        extend(this, siteConfig);
    }

    onReady() {
        var self = this;
        self.ready = true;
        /* trackEvents.init(); */
    }

    onLoad() {
        document.getElementsByTagName('body')[0].classList.add('loaded');
    }

    isReady() {
        var self = this;
        return self.ready === true;
    }

    noop() {}
}

export default BasePage;
